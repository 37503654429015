export const appSettings = {
  serverBaseUrl: process.env.REACT_APP_SERVER_BASE_URL,
  emailjsServiceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
  emailjsTemplateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
  emailjsPublicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
  recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
};

export const contactConfig = {
  callPhone: "+919311630345",
  whatsappNumber: "+919718665000",
  hiEmail: "hi@fastax.in",
  supportEmail: "support@fastax.in",

  facebook: "https://facebook.com/fastax.in",
  instagram: "https://instagram.com/fastax.in",
  linkedin: "https://www.linkedin.com/showcase/fastax",
  linkedinInfokey: "https://www.linkedin.com/company/infokeyin",
  youtube: "https://www.youtube.com/@FastaxIN",

  infokeyWebsite: "https://infokey.in",
  yatterWebsite: "https://yatter.in",
  wabmWebsite: "https://wabm.in",
};
