import React from "react";
import { FaFacebookSquare, FaLinkedin, FaYoutube } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

import { contactConfig } from "../config/appConfig";

const StickySocials = () => {
  return (
    <div className="my-social-sticky-icons text-white z-20">
      <a
        href={contactConfig.facebook}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-[#4267B2] inline-flex items-center gap-5"
      >
        <p>Facebook</p>
        <FaFacebookSquare className="social-icon" />
      </a>
      <a
        href={contactConfig.instagram}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-gradient-to-br from-[#833AB4] via-[#E1306C] to-[#FCAF45] inline-flex items-center gap-5"
      >
        <p>Instagram</p>
        <RiInstagramFill className="social-icon" />
      </a>
      <a
        href={contactConfig.linkedin}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-[#0a66c2] inline-flex items-center gap-5"
      >
        <p>LinkedIn</p>
        <FaLinkedin className="social-icon" />
      </a>
      <a
        href={contactConfig.youtube}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-[#FF0000] inline-flex items-center gap-5"
      >
        <p>YouTube</p>
        <FaYoutube className="social-icon" />
      </a>
    </div>
  );
};

export default StickySocials;
